<template>
  <div class="index-m">
    <van-notice-bar
      v-if="announce.length > 1"
      left-icon="volume-o"
      :scrollable="false"
      background="#fff"
      color="#000"
      :style="'--aog-gg-text:\'' + SYSLANG.AOG公告 + '\';'"
    >
      <van-swipe
        vertical
        class="notice-swipe"
        :autoplay="4000"
        :touchable="false"
        :show-indicators="false"
      >
        <template v-for="(item, index) in announce" :key="index">
          <van-swipe-item @click="viewAnnounce(item.ID)">{{
            item.Title
          }}</van-swipe-item>
        </template>
      </van-swipe>
    </van-notice-bar>

    <van-notice-bar
      v-else-if="announce.length == 1"
      left-icon="volume-o"
      scrollable
      background="#fff"
      color="#000"
      :style="'--aog-gg-text:\'' + SYSLANG.AOG公告 + '\';'"
      :text="announce[0].Title"
      @click="viewAnnounce(announce[0].ID)"
    ></van-notice-bar>

    <div class="my-swipe">
      <van-swipe :autoplay="8000" lazy-render>
        <van-swipe-item v-for="(url, index) in banners" :key="index">
          <img :src="url" />
        </van-swipe-item>
      </van-swipe>
    </div>

    <template v-if="videos.length > 0">
      <video
        v-for="(video, index) in videos"
        :key="index"
        class="my-video"
        controls
        preload="auto"
        loop
        muted
        webkit-playsinline="true"
        playsinline="true"
        :src="UPLOADBASE + video.VideoUrl"
        :poster="UPLOADBASE + video.VideoCover"
      ></video>
    </template>

    <!--<div class="index-goods">
    <GoodsList :list="goods" />
  </div>-->

    <div class="logo">
      <img :src="require('@/assets/images/index_logo.png')" />
    </div>
  </div>

  <div class="index-pc">
    <div class="my-swipe">
      <el-carousel
        :interval="8000"
        trigger="click"
        height="calc(100vw / 1920 * 600)"
      >
        <el-carousel-item v-for="(url, index) in pcbanners" :key="index">
          <img :src="url" style="width: 100%; height: 100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="gg-layout">
      <van-notice-bar
        v-if="announce.length > 1"
        left-icon="volume-o"
        :scrollable="false"
        background="#fff"
        color="#000"
        :style="'--aog-gg-text:\'' + SYSLANG.AOG公告 + '\';'"
      >
        <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="4000"
          :touchable="false"
          :show-indicators="false"
        >
          <template v-for="(item, index) in announce" :key="index">
            <van-swipe-item @click="viewAnnounce(item.ID)">{{
              item.Title
            }}</van-swipe-item>
          </template>
        </van-swipe>
      </van-notice-bar>

      <van-notice-bar
        v-else-if="announce.length == 1"
        left-icon="volume-o"
        scrollable
        background="#fff"
        color="#000"
        :style="'--aog-gg-text:\'' + SYSLANG.AOG公告 + '\';'"
        :text="announce[0].Title"
        @click="viewAnnounce(announce[0].ID)"
      ></van-notice-bar>
    </div>

    <div class="index-category">
      <div v-if="pdts.length > 0" class="content">
        <router-link
          :to="'/product/detail?GoodsID=' + pdts[0].id"
          target="_blank"
          style="line-height: 0"
        >
          <el-image
            :src="UPLOADBASE + pdts[0].url"
            style="width: calc(100vw / 1920 * 590)"
            lazy
          />
        </router-link>
        <div class="c">
          <router-link
            :to="'/product/detail?GoodsID=' + pdts[1].id"
            target="_blank"
            style="line-height: 0"
          >
            <el-image
              :src="UPLOADBASE + pdts[1].url"
              style="width: calc(100vw / 1920 * 590)"
              lazy
            />
          </router-link>
          <router-link
            :to="'/product/detail?GoodsID=' + pdts[2].id"
            target="_blank"
            style="line-height: 0"
          >
            <el-image
              :src="UPLOADBASE + pdts[2].url"
              style="width: calc(100vw / 1920 * 590)"
              lazy
            />
          </router-link>
        </div>
      </div>

      <!--<div class="cates">
        {{ SYSLANG.所有产品 }}
        <el-tabs v-model="cateid" class="demo-tabs" @tab-change="changeCate">
          <el-tab-pane
            v-for="(citem, cindex) in categorys"
            :key="cindex"
            :label="citem.Name"
            :name="citem.ID"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div class="index-goods">
        <GoodsList :list="goods" :pagination="false" :size="4" />
      </div>-->

      <div class="index-video-block">
        <video
          v-for="(video, index) in videos"
          :key="index"
          class="my-video"
          controls
          preload="auto"
          loop
          muted
          webkit-playsinline="true"
          playsinline="true"
          :src="UPLOADBASE + video.VideoUrl"
          :poster="UPLOADBASE + video.VideoCover"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
import { showToast, NoticeBar, showLoadingToast, closeToast } from "vant";
import { getCookie } from "../util";
//import GoodsList from "../components/GoodsList.vue";

export default {
  components: {
    //GoodsList,
  },
  data() {
    return {
      banners: [
        require("@/assets/images/m_banner_01.jpg"),
        require("@/assets/images/m_banner_02.jpg"),
        require("@/assets/images/product/b1.jpg"),
        require("@/assets/images/product/b2.jpg"),
        require("@/assets/images/product/b3.jpg"),
        require("@/assets/images/product/b4.jpg"),
        require("@/assets/images/product/b5.jpg"),
        require("@/assets/images/product/b6.jpg"),
        require("@/assets/images/product/b7.jpg"),
      ],
      pcbanners: [
        require("@/assets/images/pc/banner_01.jpg"),
        require("@/assets/images/pc/banner_02.jpg"),
        require("@/assets/images/pc_index_banner_01.jpg"),
        require("@/assets/images/pc_index_banner_02.jpg"),
        require("@/assets/images/pc_index_banner_03.jpg"),
        require("@/assets/images/pc_index_banner_04.jpg"),
      ],
      announce: [],
      categorys: [],
      cateid: 0,
      loading: false,
      goods: [],
      videos: [],
      pdts: [],
    };
  },
  beforeCreate() {
    if (!getCookie("agentloginid")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentloginid")) {
      return;
    }

    this.rootApp.use(NoticeBar);

    this.SYSLANG = this.LANG.system;
    this.GOODSLANG = this.LANG.pages.goods;
    this.UPLOADBASE = this.uploadbase;
    this.init();
  },
  methods: {
    init() {
      this.axios.post(this.actions.announce).then(({ data }) => {
        let { code, msg, list, videos, pdts } = data;

        if (code == "SUCCESS") {
          this.announce = list;
          this.videos = videos;
          this.pdts = pdts;
        } else {
          showToast({
            message:
              code == "NOTLOGIN" ? this.SYSLANG[msg] : this.GOODSLANG[msg],
            forbidClick: true,
            onClose: () => {
              if (code == "NOTLOGIN") {
                this.$router.replace({
                  path: "/login",
                });
              }
            },
          });
        }
      });

      // 读取分类列表
      this.axios.get(this.actions.goods_cates).then(({ data }) => {
        let { code, msg, categorys } = data;
        if (code != "SUCCESS") {
          showToast(msg);
        } else {
          this.categorys = categorys;
          if (categorys.length > 0) {
            this.cateid = categorys[0].ID;
            //this.getGoodsList();
          }
        }
      });
    },
    viewAnnounce(id) {
      if (isNaN(id * 1)) {
        return;
      }

      this.$router.push({
        path: "/announce",
        query: {
          id,
        },
      });
    },
    changeCate() {
      this.goods = [];
      this.getGoodsList();
    },
    getGoodsList() {
      this.loading = true;

      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.axios
        .post(this.actions.goods_list, {
          cateid: this.cateid * 1,
          curpage: 1,
          pagesize: 8,
        })
        .then(({ data }) => {
          closeToast();
          let { code, msg, goods } = data;

          if (code == "SUCCESS") {
            let { list } = goods;
            this.goods = this.goods.concat(list);
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }

          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
.van-notice-bar::before {
  content: var(--aog-gg-text);
  margin-right: 4px;
}
.notice-swipe >>> .van-swipe-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .index-pc {
    display: none;
  }

  .my-video {
    width: 100vw;
    height: 45.5vw;
    background-color: #000;
  }

  .logo {
    display: flex;
    width: 100vw;
    height: 60vw;
    justify-content: center;
    align-items: center;
  }

  .logo > img {
    width: 60%;
  }
  .my-swipe {
    width: 100vw;
    font-size: 0;
    --van-swipe-indicator-size: 8px;
    --van-swipe-indicator-inactive-background: #fff;
    --van-swipe-indicator-active-background: rgba(0, 0, 0, 0);
    --van-swipe-indicator-inactive-opacity: 1;
    --van-swipe-indicator-margin: 15px;
  }
  .my-swipe >>> .van-swipe__indicator--active {
    position: relative;
  }
  .my-swipe >>> .van-swipe__indicator--active::before,
  .my-swipe >>> .van-swipe__indicator--active::after {
    content: "";
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
  }
  .my-swipe >>> .van-swipe__indicator--active::before {
    width: 6px;
    height: 6px;
    margin-top: -3px;
    margin-left: -3px;
    background-color: #000;
  }
  .my-swipe >>> .van-swipe__indicator--active::after {
    width: 14px;
    height: 14px;
    top: -3px;
    left: -3px;
    border: 1px solid #000;
  }
  .my-swipe >>> .van-swipe__indicator:not(:last-child) {
    margin-right: 12px;
  }
}

@media (min-width: 769px) {
  .index-m {
    display: none;
  }
  .index-pc .gg-layout {
    padding: 0 calc(100vw / 1920 * 360);
    position: relative;
    height: calc(100vw / 1920 * 40);
    overflow: hidden;
  }
  .index-pc .gg-layout .van-notice-bar {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin-left: 0;
    font-size: calc(100vw / 1920 * 14);
    --van-notice-bar-padding: 0;
  }
  .index-pc .gg-layout >>> .van-swipe {
    cursor: pointer;
  }
  .index-pc .gg-layout >>> .van-swipe:hover {
    color: var(--van-card-price-color);
  }
  .my-swipe {
    width: 100%;
  }
  .my-swipe .el-carousel {
    --el-carousel-indicator-width: 8px;
    --el-carousel-indicator-height: 8px;
    --el-carousel-indicator-padding-horizontal: 19px;
  }
  .my-swipe >>> .el-carousel .el-carousel__button {
    opacity: 1;
    border-radius: 50%;
    position: relative;
  }
  .my-swipe >>> .el-carousel .is-active .el-carousel__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }
  .my-swipe >>> .el-carousel .is-active .el-carousel__button::before,
  .my-swipe >>> .el-carousel .is-active .el-carousel__button::after {
    content: "";
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
  }
  .my-swipe >>> .el-carousel .is-active .el-carousel__button::before {
    width: 6px;
    height: 6px;
    background-color: #000;
  }
  .my-swipe >>> .el-carousel .is-active .el-carousel__button::after {
    width: 14px;
    height: 14px;
    border: 1px solid #000;
  }
  .index-category {
    padding: calc(100vw / 1920 * 40) calc(100vw / 1920 * 360);
    background-color: #f7f7f7;
  }
  .index-category .content {
    display: flex;
    justify-content: space-between;
  }
  .index-category .content .c {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .index-category .cates {
    padding-top: calc(100vw / 1920 * 36);
    height: calc(100vw / 1920 * 44);
    font-size: 20px;
    color: #000;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .index-category .cates >>> .el-tabs__header {
    margin-bottom: 0;
    --el-tabs-header-height: calc(100vw / 1920 * 44);
    --el-border-color-light: rgba(0, 0, 0, 0);
    --el-font-size-base: 16px;
    --el-text-color-primary: #666;
    --el-color-primary: #000;
  }
  .index-category .index-goods {
    padding-top: calc(100vw / 1920 * 28);
  }
  .index-video-block {
    padding-top: calc(100vw / 1920 * 40);
  }
  .index-video-block .my-video {
    width: 100%;
    height: calc(100vw / 1920 * 545);
  }
}
</style>
